// const ITTYBIT_API_VERSION = "latest";

const mode = (
  process.env.MODE ||
  process.env.NEXT_PUBLIC_MODE ||
  'production'
).toLowerCase();

console.log({ mode });

function getAppUrl() {
  if (mode === 'production') return 'https://ittybit.com';
  if (mode === 'staging') return 'https://ittybit.dev';
  return 'http://localhost:3000';
}

function getApiUrl() {
  if (mode === 'production') return 'https://api.ittybit.com';
  if (mode === 'staging') return 'https://api.ittybit.dev';
  return 'http://localhost:8124';
}

function getPlayerUrl() {
  if (mode === 'production') return 'https://player.ittybit.com';
  if (mode === 'staging') return 'https://player.ittybit.dev';
  return 'http://localhost:4000';
}

function getHeaders({ token = null }) {
  const headers = {
    'Content-Type': 'application/json',
    // "Ittybit-Version": ITTYBIT_API_VERSION,
  };
  if (token) {
    headers['Ittybit-Token'] = token;
  }
  return headers;
}

async function apiRequest({ url, options }) {
  try {
    let response = await fetch(url, { cache: 'no-store', ...options });
    let json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function getRequest({ token, url, config = {} }) {
  let options = {
    method: 'GET',
    headers: getHeaders({ token }),
    ...config,
  };
  return apiRequest({ url, options });
}

export function postRequest({ token, url, payload = null, config = {} }) {
  let options = {
    method: 'POST',
    headers: getHeaders({ token }),
    ...config,
  };
  if (payload) {
    options.body = JSON.stringify(payload);
  }
  return apiRequest({ url, options });
}

export function deleteRequest({ token, url, config = {} }) {
  let options = {
    method: 'DELETE',
    headers: getHeaders({ token }),
    ...config,
  };
  return apiRequest({ url, options });
}

export const API_BASE_URL = getApiUrl();
export const APP_BASE_URL = getAppUrl();
export const PLAYER_BASE_URL = getPlayerUrl();
export const STATIC_BASE_URL = 'https://ittybit-app.ittybitcdn.com';

export const SOURCE_KINDS = ['image', 'video', 'audio'];
export const INTELLIGENCE_KINDS = [
  'faces',
  'text',
  'objects',
  'speech',
  'nsfw',
  'summary',
  'prompt',
  'description',
  'tags',
  'sentiment',
  'outline',
];
export const TRACKS_KINDS = ['chapters', 'thumbnails', 'subtitles', 'captions'];

export const endpoints = {
  ip: `${API_BASE_URL}/ip`,
  login: `${API_BASE_URL}/login`,
  users: `${API_BASE_URL}/users`,
  user: `${API_BASE_URL}/user`,
  organisations: `${API_BASE_URL}/organisations`,
  organisation: `${API_BASE_URL}/organisations/:id`,
  projects: `${API_BASE_URL}/projects`,
  project: `${API_BASE_URL}/projects/:id`,
  billingManagementUrl: `${API_BASE_URL}/billing/management_url`,
  billingSubscriptions: `${API_BASE_URL}/billing/subscriptions`,
  billingCheckoutSession: `${API_BASE_URL}/billing/checkout/session`,
  billingCard: `${API_BASE_URL}/billing/card`,
  billingPlan: `${API_BASE_URL}/billing/plans`,
  billingProduct: `${API_BASE_URL}/billing/product`,
  billingCustomer: `${API_BASE_URL}/billing/customer`,
  billing: `${API_BASE_URL}/billing`,
  billingStats: `${API_BASE_URL}/billing/stats`,
  billingInvoices: `${API_BASE_URL}/billing/invoices`,
  billingSetupIntent: `${API_BASE_URL}/billing/setup-intent`,
  billingSetupIntentComplete: `${API_BASE_URL}/billing/setup-intent`,
  billingSubscription: `${API_BASE_URL}/billing/subscription`,
  billingSubscriptionPlans: `${API_BASE_URL}/billing/subscription/plans`,
  billingSubscriptionPreviewChange: `${API_BASE_URL}/billing/subscription/preview-change`,
  billingSubscriptionMakeChange: `${API_BASE_URL}/billing/subscription/change`,
  billingSubscriptionAddons: `${API_BASE_URL}/billing/subscription/addons`,
  billingSubscriptionAddonsPreview: `${API_BASE_URL}/billing/subscription/addons-preview`,
  billingUrl: `${API_BASE_URL}/billing/portal`,
  checkout: `${API_BASE_URL}/billing/checkout`,
  checkoutSubscription: `${API_BASE_URL}/billing/checkout/subscription`,
  keys: `${API_BASE_URL}/keys`,
  key: `${API_BASE_URL}/keys/:id`,
  logs: `${API_BASE_URL}/logs`,
  log: `${API_BASE_URL}/logs/:id`,
  events: `${API_BASE_URL}/events`,
  event: `${API_BASE_URL}/events/:id`,
  tasks: `${API_BASE_URL}/tasks`,
  task: `${API_BASE_URL}/tasks/:id`,
  automations: `${API_BASE_URL}/automations`,
  automation: `${API_BASE_URL}/automations/:id`,
  workflows: `${API_BASE_URL}/workflows`,
  workflow: `${API_BASE_URL}/workflows/:id`,
  webhooks: `${API_BASE_URL}/webhooks`,
  webhook: `${API_BASE_URL}/webhooks/:id`,
  uploads: `${API_BASE_URL}/uploads`,
  media: `${API_BASE_URL}/media`,
  item: `${API_BASE_URL}/media/:id`,
  files: `${API_BASE_URL}/files`,
  folders: `${API_BASE_URL}/folders`,
  folder: `${API_BASE_URL}/folders/:folder`,
  authUrl: `${API_BASE_URL}/user/authentication-url`,
  acceptInvite: `${API_BASE_URL}/user/accept-invite`,
  access: `${API_BASE_URL}/access`,
  sign: `${API_BASE_URL}/sign`,
  beta_access: `${API_BASE_URL}/beta_user`,
  domains: `${API_BASE_URL}/domains`,
  domain: `${API_BASE_URL}/domains/:id`,
};
