'use client';

import { useUser } from '@/api/hooks';
import Link from '@/components/Link';

export default function Footer() {
  const { user, loading } = useUser();

  if (loading) return null;

  const marketingItems = [
    { title: 'Home', href: user ? '/home' : '/' },
    { title: 'Pricing', href: '/pricing' },
    { title: 'Docs', href: '/docs' },
    { title: 'Guides', href: '/guides' },
    { title: 'API', href: '/docs/api' },
    { title: 'Changelog', href: '/docs/changelog' },
  ];
  let defaultItems = [];
  if (user) {
    defaultItems.push(...[{ title: 'Dashboard', href: '/dashboard' }]);
  }
  defaultItems.push(
    ...[
      { title: 'About', href: '/about' },
      { title: 'Support', href: '/support' },
      { title: 'Terms', href: '/terms' },
      { title: 'Privacy', href: '/privacy' },
    ],
  );

  return (
    <footer className="sticky top-0 mt-auto pt-16 pb-24">
      <div className="px-4 md:px-8 py-8 flex flex-col md:flex-row items-start md:items-start justify-between space-y-8 md:space-y-0 md:space-x-16">
        <ul className="space-y-2">
          {marketingItems.map(item => (
            <li key={item.href}>
              <Link href={item.href} className="text-sm text-3 hover:text-1">
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
        <ul className="space-y-2">
          {defaultItems.map(item => (
            <li key={item.href}>
              <Link href={item.href} className="text-sm text-3 hover:text-1">
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
        <p className="text-sm text-4 md:text-right md:!ml-auto">
          Ittybit Ltd
          <br />
          107 Cheapside
          <br />
          London
          <br />
          EC2V 6DN
          <span className="block mt-4">Company No. 13198590</span>
        </p>
      </div>
      <div className="flex items-end justify-between">
        <span className="flex flex-col text-sm md:text-base text-2 px-4 md:px-8">
          <span className="flex items-center gap-x-1">
            <span className="whitespace-nowrap">
              <span>Made with</span>
              <span className="text-xl">🥤</span>
              <span>in</span>
            </span>
            <span className="text-xl">🇬🇧</span>
            <span className="text-xl">🇦🇹</span>
            {/* <span className="text-xl">🇦🇺</span> */}
            <span className="text-xl">🇸🇪</span>
            <span>and</span>
            <span className="text-xl">🇺🇸</span>
          </span>
          <span className="text-4 flex items-center gap-x-1">
            <span>(and we're hiring</span>
            <span className="text-xl">🌏</span>
            <span>remotely)</span>
          </span>
        </span>
        <span className="block text-sm text-3 px-4 md:px-8">
          &copy; {new Date().getFullYear()}
        </span>
      </div>
    </footer>
  );
}
