'use client';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';

import { Button } from '@/components/ui/button';
import IconBox from '@/components/IconBox';
import Link from '@/components/Link';
import { useUser } from '@/api/hooks';

const menuItems = [
  {
    icon: 'upload',
    label: 'Hosting',
    href: '/apis/hosting',
    description: 'Upload, store, and deliver video, image, and audio files',
    color: 'blue',
  },
  {
    icon: 'intelligence',
    label: 'Intelligence',
    href: '/apis/intelligence',
    description: 'Make your video, image, and audio files more useful',
    color: 'magenta',
  },
  {
    icon: 'sources',
    label: 'Sources',
    href: '/apis/sources',
    description: 'Convert, compress, and transform your media',
    color: 'green',
  },
  {
    icon: 'tracks',
    label: 'Tracks',
    href: '/apis/tracks',
    description: "Enhance your media player's user experience",
    color: 'orange',
  },
  {
    icon: 'automation',
    label: 'Automation',
    href: '/apis/automation',
    description: 'Handle millions of files with powerful media workflows',
    color: 'yellow',
  },
];

const docsItems = [
  {
    icon: 'quickstart',
    label: 'Quickstart',
    href: '/docs#quickstart',
    description: 'Get started in seconds',
    color: 'purple',
  },
  {
    icon: 'code',
    label: 'API Reference',
    href: '/docs/api',
    description: "Learn about ittybit's RESTful APIs",
    color: 'purple',
  },
  {
    icon: 'education',
    label: 'How-To Guides',
    href: '/guides',
    description: 'View example projects and tutorials',
    color: 'purple',
  },
  {
    icon: 'workflows',
    label: 'Changelog',
    href: '/docs/changelog',
    description: "See what's new in ittybit",
    color: 'purple',
  },
];

function NavigationLink({ icon, label, href }) {
  return (
    <Link
      href={href}
      className="flex items-center gap-3 px-3 py-2 font-medium opacity-80 hover:opacity-100 flex-shrink-0">
      <IconBox icon={icon} color="purple" size="lg" />
      <div className="flex flex-col text-base text-right whitespace-nowrap">
        {label}
      </div>
    </Link>
  );
}

function Dropdown({ items, href, icon, label }) {
  return (
    <Popover className="relative group">
      <PopoverButton>
        <Link
          href={href}
          className="flex items-center gap-3 px-3 py-2 font-medium opacity-80 hover:opacity-100 flex-shrink-0">
          <div className="block group-hover:hidden group-focus-within:hidden">
            <IconBox icon={icon} color="purple" size="lg" />
          </div>
          <div className="hidden group-hover:block group-focus-within:block">
            <IconBox icon="expand" color="purple" size="lg" />
          </div>
          <span className="flex flex-col text-base text-right whitespace-nowrap">
            {label}
          </span>
        </Link>
      </PopoverButton>

      <PopoverPanel
        static
        className="absolute left-0 z-10 mt-0 flex w-screen max-w-max -translate-x-4 px-4 transition translate-y-1 opacity-0 pointer-events-none group-hover:opacity-100 group-focus-within:opacity-100 group-hover:translate-y-0 group-focus-within:translate-y-0 group-hover:pointer-events-auto group-focus-within:pointer-events-auto">
        <div className="w-screen max-w-md flex-auto overflow-hidden rounded-xl bg-dark-400 text-base ring-1 ring-brand-purple/5 lg:max-w-md">
          <div className="grid grid-cols-1 gap-x-6 gap-y-1 p-4">
            {items.map(item => (
              <div
                key={item.label}
                className="group relative flex items-start gap-x-6 rounded-lg p-4 hover:bg-dark-500">
                <IconBox icon={item.icon} color={item.color} size="lg" />
                <div>
                  <Link href={item.href} className="font-medium text-1">
                    {item.label}
                    <span className="absolute inset-0" />
                  </Link>
                  <p className="mt-1 text-4">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
          <Link
            href={href}
            className="block text-center text-4 hover:text-1 rounded-lg p-4 hover:bg-dark-500">
            <span className="text-base">View all</span>
          </Link>
        </div>
      </PopoverPanel>
    </Popover>
  );
}

function ProductsDropdown() {
  return (
    <Dropdown items={menuItems} href="/apis" icon="terminal" label="Features" />
  );
}

function DocsDropdown() {
  return <Dropdown items={docsItems} href="/docs" icon="docs" label="Docs" />;
}

export default function PublicNavigation() {
  const { user } = useUser();

  return (
    <nav className="sticky top-0 z-50 w-full bg-dark-100/90 backdrop-blur-md">
      <div className="px-4 md:px-6 xl:px-12 py-2 flex justify-between items-center">
        <div className="flex items-center">
          <Link href="/">
            <img
              src="https://ittybit-app.ittybitcdn.com/ittybit-logo-2025-rgb-pixel%402x.png"
              alt="ittybit"
              className="h-10 xl:h-12 mr-8 xl:mr-12"
            />
          </Link>
          <div className="hidden xl:flex space-x-8">
            <ProductsDropdown />
            <DocsDropdown />
            <NavigationLink icon="pricing" label="Pricing" href="/pricing" />
          </div>
        </div>
        <div className="flex items-center space-x-6">
          <div className="hidden xl:block">
            <NavigationLink icon="help" label="Support" href="/support" />
          </div>
          {user ? (
            <Button
              href="/dashboard"
              variant="default"
              size="md"
              className="bg-brand-500/10 hover:bg-brand-500/20 text-2 hover:text-1">
              Dashboard
            </Button>
          ) : (
            <>
              <Button
                href="/login"
                variant="default"
                size="md"
                className="hidden xl:flex bg-brand-500/10 hover:bg-brand-500/20 text-2 hover:text-1">
                Login
              </Button>
              <Button
                href="/register"
                variant="primary"
                size="md"
                className="bg-brand-500/80 hover:bg-brand-300/80 text-1">
                Get API Key
              </Button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}
