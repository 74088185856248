'use client';

import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { cn } from '@/lib/utils';
import { Spinner } from './spinner';
import Link from '../Link';

const buttonVariants = cva(
  'whitespace-nowrap inline-flex items-center justify-center text-center font-bold ring-0 focus:ring-2 outline-none rounded-sm disabled:bg-brand-500/50 disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        default:
          'bg-white/10 hover:bg-white/20 text-2 hover:text-1 \
          disabled:bg-white/10 disabled:hover:bg-white/10 disabled:hover:text-2',
        primary: 'bg-brand-500 hover:bg-brand-300 text-white \
          disabled:bg-brand-500 disabled:hover:bg-brand-500 disabled:hover:text-white',
        secondary:
          'bg-brand-purple/10 hover:bg-brand-purple/20 text-2 hover:text-1 \
          disabled:bg-brand-purple/10 disabled:hover:bg-brand-purple/10 disabled:hover:text-2',
        destructive:
          'bg-brand-red/10 hover:bg-brand-red/20 text-white/80 hover:text-white \
          disabled:bg-brand-red/10 disabled:hover:bg-brand-red/10 disabled:text-white/80 disabled:hover:text-white/80',
        muted:
          'bg-transparent hover:bg-white/10 text-white/50 hover:text-white/80 \
          disabled:bg-white/10 disabled:hover:bg-white/10 disabled:hover:text-white/50',
      },
      size: {
        xs: 'min-h-[1rem] lg:min-h-[1.5rem] text-2xs lg:text-xs px-2',
        sm: 'min-h-[1.5rem] lg:min-h-[2rem] text-xs lg:text-sm px-3',
        md: 'min-h-[2rem] lg:min-h-[2.5rem] text-sm lg:text-base px-4',
        lg: 'min-h-[2rem] lg:min-h-[3rem] text-base lg:text-lg px-8',
        xl: 'min-h-[3rem] lg:min-h-[4rem] text-lg lg:text-xl px-12 lg:px-16',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
  },
);

const Button = React.forwardRef(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      href = null,
      disabled,
      loading,
      onClick,
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    const [isLoading, setIsLoading] = React.useState(false);

    const handleClick = async e => {
      if (!onClick) return;

      try {
        setIsLoading(true);
        await onClick(e);
      } finally {
        setIsLoading(false);
      }
    };

    const showLoading = loading || isLoading;

    if (href) {
      return (
        <Link
          href={href}
          className={cn(buttonVariants({ variant, size }), className)}
          onClick={handleClick}
          {...props}>
          {children}
        </Link>
      );
    }

    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size }),
          (disabled || showLoading) &&
            'cursor-not-allowed opacity-20',
          className,
        )}
        ref={ref}
        disabled={disabled || showLoading}
        onClick={handleClick}
        {...props}>
        {showLoading ? (
          <div className="flex items-center gap-2">
            <Spinner />
          </div>
        ) : (
          children
        )}
      </Comp>
    );
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
