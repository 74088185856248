import { twMerge } from 'tailwind-merge';
import { clsx } from 'clsx';
import { INTELLIGENCE_KINDS, SOURCE_KINDS, TRACKS_KINDS } from '@/api';
import lodashSet from 'lodash.set';
import lodashGet from 'lodash.get';
import lodashPick from 'lodash.pick';
import fastDeepEqual from 'fast-deep-equal';

export function formatFilesize({
  filesize = 0,
  decimals = 2,
  roundUp = false,
}) {
  if (filesize == 0) return '0 Bytes';
  const k = 1000;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = Math.floor(Math.log(filesize) / Math.log(k));
  const value = filesize / Math.pow(k, i);
  const rounded = roundUp ? Math.ceil(value) : value;
  return rounded.toFixed(decimals) + ' ' + sizes[i];
}

export function formatTimestamp(timestamp) {
  if (!timestamp) return null;
  return new Date(timestamp).toISOString().replace('T', ' ').split('.')[0];
}

export function formatDate(date) {
  if (!date) return null;
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const d = new Date(date);
  const day = d.getDate();
  const monthIndex = d.getMonth();
  const month = months[monthIndex];
  const year = d.getFullYear();
  return `${month} ${day}, ${year}`;
}

export function formatDuration(duration) {
  let formattedDuration = null;
  if (duration > 3600) {
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration % 3600) / 60);
    let seconds = Math.floor((duration % 3600) % 60);
    formattedDuration = `${hours}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } else if (duration > 60) {
    let minutes = Math.floor(duration / 60);
    let seconds = Math.floor(duration % 60);
    formattedDuration = `${minutes}:${seconds.toString().padStart(2, '0')}`;
  } else if (duration) {
    let seconds = Math.floor(duration);
    formattedDuration = `0:${seconds.toString().padStart(2, '0')}`;
  }
  return formattedDuration;
}

export function initFadeInObserver() {
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    },
    {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    },
  );

  const fadeElements = document.querySelectorAll('.fade-in');
  fadeElements.forEach(el => observer.observe(el));
}

export function getFileIconAndColor({ file }) {
  if (!file) {
    return {
      icon: 'transform',
      color: 'pink',
    };
  }
  const { kind, object } = file;
  let fileIcon = kind;
  let fileColor = 'purple';
  if (object === 'source') fileColor = 'green';
  if (object === 'track') fileColor = 'orange';
  if (object === 'intelligence') fileColor = 'magenta';
  return { icon: fileIcon, color: fileColor };
}

export function getTaskIconAndColor({ task }) {
  if (!task) {
    return {
      inputIcon: 'transform',
      inputColor: 'pink',
      outputIcon: 'transform',
      outputColor: 'pink',
    };
  }

  const { kind, input, output, options } = task;

  let { kind: optionsKind } = options || {};
  let outputKind = kind || output?.kind || optionsKind || input?.kind || 'task';

  let inputKind = input?.kind || 'file';

  let inputIcon = inputKind;
  let inputColor = 'pink';
  if (input) {
    if (input.external_url) {
      inputIcon = 'cdn';
      inputColor = 'blue';
    }
    if (input.object === 'source') {
      inputIcon = input.kind || 'source';
      inputColor = 'green';
    }
    if (input.object === 'intelligence') {
      inputIcon = input.kind || 'intelligence';
      inputColor = 'magenta';
    }
    if (input.object === 'track') {
      inputIcon = input.kind || 'track';
      inputColor = 'orange';
    }
  }

  let outputIcon = outputKind || 'transform';
  let outputColor = 'pink';
  if (outputKind) {
    if (outputKind === 'conditions') {
      outputIcon = 'logs';
      outputColor = 'purple';
    } else if (outputKind === 'ingest') {
      if (!input) {
        inputIcon = 'cdn';
      }
      outputColor = 'blue';
    } else if (outputKind === 'automation') {
      outputColor = 'yellow';
    } else if (outputKind === 'workflow') {
      outputColor = 'purple';
    } else if (SOURCE_KINDS.includes(outputKind)) {
      outputColor = 'green';
    } else if (INTELLIGENCE_KINDS.includes(outputKind)) {
      outputColor = 'magenta';
    } else if (TRACKS_KINDS.includes(outputKind)) {
      outputColor = 'orange';
    }
  } else if (output) {
    if (output.object === 'source') {
      outputIcon = 'source';
      outputColor = 'green';
    } else if (output.object === 'intelligence') {
      outputIcon = 'intelligence';
      outputColor = 'magenta';
    } else if (output.object === 'track') {
      outputIcon = 'track';
      outputColor = 'orange';
    }
  } else if (options?.format) {
    outputIcon = 'sources';
    outputColor = 'green';
  }

  return { inputIcon, inputColor, outputIcon, outputColor };
}

export function getTaskStatusIconAndColor({ status }) {
  let taskIcon = 'transform';
  let statusColors = 'text-brand-pink';
  if (status === 'cancelled') {
    taskIcon = 'cross';
    statusColors = 'text-white/40';
  }
  if (status === 'waiting') {
    taskIcon = 'tasks';
    statusColors = 'text-white/50';
  }
  if (status === 'queued') {
    taskIcon = 'tasks';
    statusColors = 'text-brand-yellow';
  }
  if (status === 'downloading') {
    taskIcon = 'ingest';
    statusColors = 'text-brand-pink';
  }
  if (status === 'processing') {
    taskIcon = 'transform';
    statusColors = 'text-brand-pink animate-pulse';
  }
  if (status === 'encoding') {
    taskIcon = 'transform';
    statusColors = 'text-brand-pink animate-pulse';
  }
  if (status === 'finishing') {
    statusColors = 'text-brand-blue animate-pulse';
    taskIcon = 'upload';
  }
  if (status === 'paused') {
    statusColors = 'text-white/50';
    taskIcon = 'pause';
  }
  if (status === 'ready' || status === 'complete') {
    statusColors = 'text-brand-success';
    taskIcon = 'check';
  }
  if (status === 'error') {
    statusColors = 'text-brand-error';
    taskIcon = 'error';
  }

  return { taskIcon, statusColors };
}

/**
 * Merges and deduplicates Tailwind CSS class names
 * @param {...ClassValue[]} inputs - Class name values to merge
 * @returns {string} Merged and deduplicated class names
 */
export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function isDev() {
  const mode = getMode();
  return mode === 'development';
}

export function getMode() {
  return (
    process.env.MODE ||
    process.env.NEXT_PUBLIC_MODE ||
    'production'
  ).toLowerCase();
}

export const set = lodashSet;
export const get = lodashGet;
export const isEqual = fastDeepEqual;
export const pick = lodashPick;
